<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Product" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description</span>
                  <ckeditor v-model="description" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.description" style="color: red;">{{ errors.description[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Content</span>
                  <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.content" style="color: red;">{{ errors.content[0] }}</div>
                </div>
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="image"
                    @input="delete errors.image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('Product[image]','ProductImage', 'image', image[0])">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Icon">
                  <div v-if="iconExisting">
                    <a style="display: block;" target="_blank" :href="iconExisting"><img style="max-width: 400px;" :src="iconExisting"></a>
                    <div><va-button small color="danger" @click="iconExisting = deleteImage(iconId) ? '' : iconExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.icon"
                    v-model="icon"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('Product[icon]','ProductIcon', 'icon', icon[0])">Завантажити</va-button>
                  <div v-if="errors.icon" style="color: red;">{{ errors.icon[0] }}</div>
                </va-card>
                <va-toggle label="Show On Home" small v-model="showOnHome"/>
                <va-toggle label="Published" small v-model="published"/>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      label: '',
      alias: '',
      description: '',
      content: '',
      image: [],
      imageId: '',
      imageExisting: '',
      icon: [],
      iconId: '',
      iconExisting: '',
      showOnHome: false,
      published: false,
      position: '',

      sign: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, entityAttribute, attr, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('file_id', '0')
      formData.append('entity_attribute', entityAttribute)
      formData.append('attribute', attr)
      formData.append('model_name', 'backend\\modules\\product\\models\\Product')
      if (this.$attrs.id) {
        formData.append('id', this.$attrs.id)
      } else {
        formData.append('sign', this.sign)
      }
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_AGRO_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        alias: this.alias,
        description: this.description,
        content: this.content,
        show_on_home: this.showOnHome ? 1 : 0,
        published: this.published ? 1 : 0,
        position: this.position,

        sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/product/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-activity-products' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/product?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-activity-products' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/product/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.alias = response.data.alias
            this.description = response.data.description ? response.data.description : ''
            this.content = response.data.content ? response.data.content : ''
            this.imageExisting = response.data.image ? process.env.VUE_APP_AGRO_ADMIN + response.data.image : ''
            this.imageId = response.data.imageId
            this.iconExisting = response.data.icon ? process.env.VUE_APP_AGRO_ADMIN + response.data.icon : ''
            this.iconId = response.data.iconId
            this.showOnHome = !!response.data.show_on_home
            this.published = !!response.data.published
            this.position = response.data.position
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
